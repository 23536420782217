import axios from 'axios'

export default {
  fetchSubscription (id) {
    return axios.get('subscriptions/' + id + '?expand=client,client.timetables,payments,paymentsAndBills,bills,billsAmount,paymentsAmount,balance,lessons,lessons.timetable').then(response => {
      return response.data
    })
  },

  fetchSubscriptionsByDates (start, end) {
    return axios.get('subscriptions/bydates?start=' + start + '&end=' + end).then(response => {
      return response.data
    })
  },

  updateSubscription (subscription) {
    return axios.put('subscriptions/' + subscription.id + '?sort=-id&expand=client,payments,paymentsAndBills,bills,billsAmount,paymentsAmount,balance,lessons,lessons.timetable', subscription).then(response => {
      return response.data
    })
  },

  createSubscription (subscription) {
    return axios.post('subscriptions?expand=client', subscription).then(response => {
      return response.data
    })
  },

  removeSubscription (id) {
    return axios.delete('subscriptions/' + id).then(response => {
      return response.data
    })
  },

  changeSubscriptionPrice (id, newPrice) {
    return axios.post('subscriptions/changeprice', { id: id, newPrice: newPrice }).then(response => {
      return response.data
    })
  },

  fetchSubscriptionPayment (id) {
    return axios.get('payments/' + id).then(response => {
      return response.data
    })
  },

  updateSubscriptionPayment (payment) {
    return axios.put('payments/' + payment.id, payment).then(response => {
      return response.data
    })
  },

  createSubscriptionPayment (payment) {
    return axios.post('payments', payment).then(response => {
      return response.data
    })
  },

  removeSubscriptionPayment (payment) {
    return axios.delete('payments/' + payment.id).then(response => {
      return response.data
    })
  },

  fetchSubscriptionTemplates () {
    return axios.get('subscriptiontemplates').then(response => {
      return response.data
    })
  },

  fetchSubscriptionTemplate (id) {
    return axios.get('subscriptiontemplates/' + id).then(response => {
      return response.data
    })
  },

  updateSubscriptionTemplate (template) {
    return axios.put('subscriptiontemplates/' + template.id, template).then(response => {
      return response.data
    })
  },

  createSubscriptionTemplate (template) {
    return axios.post('subscriptiontemplates', template).then(response => {
      return response.data
    })
  },

  removeSubscriptionTemplate (id) {
    return axios.delete('subscriptiontemplates/' + id).then(response => {
      return response.data
    })
  },

  fetchCLS (clientIds, lessonsIds) {
    return axios.post('subscriptions/getcls', { clientIds: clientIds, lessonsIds: lessonsIds }).then(response => {
      return response.data
    })
  },

}
