var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "calendar", fluid: "", tag: "section" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", md: "12" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-sheet",
                        [_c("v-btn-toggle", { attrs: { color: "secondary" } })],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: {
                            click: function($event) {
                              return _vm.prev()
                            }
                          }
                        },
                        [
                          _c("v-icon", [
                            _vm._v(
                              "\n              mdi-chevron-left\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: {
                            click: function($event) {
                              return _vm.next()
                            }
                          }
                        },
                        [
                          _c("v-icon", [
                            _vm._v(
                              "\n              mdi-chevron-right\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary" },
                          on: {
                            click: function($event) {
                              return _vm.today()
                            }
                          }
                        },
                        [_vm._v("\n            Сегодня\n          ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "ml-2 mr-2" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": "mdi-magnify",
                              label: "Поиск по фамилии, номеру телефона",
                              "single-line": "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "Группа",
                              items: _vm.timetables,
                              "item-value": "id",
                              "item-text": "name"
                            },
                            model: {
                              value: _vm.timetable,
                              callback: function($$v) {
                                _vm.timetable = $$v
                              },
                              expression: "timetable"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-select", {
                            attrs: {
                              color: "secondary",
                              "item-color": "secondary",
                              label: "Статус",
                              "item-value": "tag",
                              "item-text": "name",
                              multiple: "",
                              items: _vm.states
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function(ref) {
                                  var attrs = ref.attrs
                                  var item = ref.item
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mx-3 mb-2 v-sheet",
                                            attrs: {
                                              "active-class":
                                                "secondary elevation-4 white--text",
                                              elevation: "0"
                                            }
                                          },
                                          "v-list-item",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              attrs: {
                                                "item-value": "item.id",
                                                "return-object": ""
                                              },
                                              domProps: {
                                                textContent: _vm._s(item.name)
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-scale-transition",
                                          [
                                            attrs.inputValue
                                              ? _c(
                                                  "v-list-item-icon",
                                                  { staticClass: "my-3" },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-check")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.stateFilter,
                              callback: function($$v) {
                                _vm.stateFilter = $$v
                              },
                              expression: "stateFilter"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-select", {
                            attrs: {
                              color: "secondary",
                              "item-color": "secondary",
                              label: "Причина отказа",
                              "item-value": "tag",
                              "item-text": "name",
                              multiple: "",
                              items: _vm.results
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function(ref) {
                                  var attrs = ref.attrs
                                  var item = ref.item
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mx-3 mb-2 v-sheet",
                                            attrs: {
                                              "active-class":
                                                "secondary elevation-4 white--text",
                                              elevation: "0"
                                            }
                                          },
                                          "v-list-item",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              attrs: {
                                                "item-value": "item.id",
                                                "return-object": ""
                                              },
                                              domProps: {
                                                textContent: _vm._s(item.name)
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-scale-transition",
                                          [
                                            attrs.inputValue
                                              ? _c(
                                                  "v-list-item-icon",
                                                  { staticClass: "my-3" },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-check")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.resultFilter,
                              callback: function($$v) {
                                _vm.resultFilter = $$v
                              },
                              expression: "resultFilter"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c("v-switch", {
                            attrs: { label: _vm.resourcesWithEventsCount },
                            model: {
                              value: _vm.onlyWithEvents,
                              callback: function($$v) {
                                _vm.onlyWithEvents = $$v
                              },
                              expression: "onlyWithEvents"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-sheet",
                    { attrs: { flat: "" } },
                    [
                      _c("full-calendar", {
                        ref: "fullCalendar",
                        staticClass: "demo-app-calendar",
                        attrs: {
                          height: "auto",
                          lang: "ru",
                          "resource-area-width": "180px",
                          "scheduler-license-key":
                            "GPL-My-Project-Is-Open-Source",
                          "default-view": "resourceTimelineMonth",
                          header: {
                            left: "",
                            center: "",
                            right: ""
                          },
                          "slot-width": "20",
                          "resource-label-text": "ФИО",
                          "resource-order": "title",
                          "filter-resources-with-events": _vm.onlyWithEvents,
                          plugins: _vm.calendarPlugins,
                          weekends: _vm.calendarWeekends,
                          "slot-label-format": _vm.slotLabelFormat,
                          locale: _vm.locale,
                          events: _vm.events,
                          resources: _vm.filteredResources,
                          "resource-render": _vm.resourceRender,
                          "event-data-transform": _vm.eventDataTransform
                        },
                        on: {
                          eventClick: _vm.eventClick,
                          dateClick: _vm.dateClick
                        }
                      }),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "min-width": "350px",
                            "close-on-content-click": false,
                            "close-on-click": false,
                            activator: _vm.selectedElement,
                            "full-width": "",
                            "offset-y": ""
                          },
                          model: {
                            value: _vm.selectedOpen,
                            callback: function($$v) {
                              _vm.selectedOpen = $$v
                            },
                            expression: "selectedOpen"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: { color: "ma-0 grey lighten-4", flat: "" }
                            },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    color:
                                      _vm.selectedEvent.extendedProps
                                        .colorClass,
                                    dark: ""
                                  }
                                },
                                [
                                  _c("v-toolbar-title", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            _vm.selectedEvent.start
                                          )
                                        ) +
                                        " — " +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            _vm.minusDay(_vm.selectedEvent.end)
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ]),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.selectedOpen = false
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-close")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _vm.selectedEvent.extendedProps.groupLabel
                                    ? _c(
                                        "div",
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectedEvent.extendedProps
                                                  .groupLabel
                                              ) + ":"
                                            )
                                          ]),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2",
                                              attrs: { small: "", outlined: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.selectedEvent
                                                      .extendedProps.groupValue
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.selectedEvent.extendedProps.paymentLabel
                                    ? _c(
                                        "div",
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectedEvent.extendedProps
                                                  .paymentLabel
                                              ) + ":"
                                            )
                                          ]),
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                small: "",
                                                color:
                                                  _vm.selectedEvent
                                                    .extendedProps.colorClass
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.selectedEvent
                                                      .extendedProps
                                                      .paymentValue
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.selectedEvent.extendedProps.countLabel
                                    ? _c(
                                        "div",
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectedEvent.extendedProps
                                                  .countLabel
                                              ) + ":"
                                            )
                                          ]),
                                          _c("v-progress-linear", {
                                            attrs: {
                                              value:
                                                _vm.selectedEvent.extendedProps
                                                  .lessonsProgress,
                                              color: "blue-grey",
                                              height: "25",
                                              reactive: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var value = ref.value
                                                    return [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.selectedEvent
                                                              .extendedProps
                                                              .countValue
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1915229114
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.selectedEvent.extendedProps.payments &&
                                  _vm.selectedEvent.extendedProps.payments
                                    .length > 0
                                    ? _c(
                                        "base-material-card",
                                        {
                                          staticClass: "px-5 py-3",
                                          attrs: {
                                            color: "success",
                                            dark: "",
                                            icon: "mdi-cash",
                                            inline: "",
                                            title: "Оплаты абонемента"
                                          }
                                        },
                                        [
                                          _c("v-simple-table", [
                                            _c("thead", [
                                              _c("tr", [
                                                _c("th", [_vm._v("#")]),
                                                _c("th", [_vm._v("Дата")]),
                                                _c("th", [_vm._v("Сумма")]),
                                                _c("th"),
                                                _c("th", {
                                                  staticClass: "text-right"
                                                })
                                              ])
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.selectedEvent.extendedProps
                                                  .payments,
                                                function(item) {
                                                  return _c(
                                                    "tr",
                                                    { key: item.id },
                                                    [
                                                      _c("td", [
                                                        _vm._v(_vm._s(item.id))
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatDateFromUnixTime"
                                                            )(item.created_date)
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.payment)
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.comment)
                                                        )
                                                      ]),
                                                      _c("td", {
                                                        staticClass:
                                                          "text-right"
                                                      })
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]),
                                          _vm.selectedEvent.extendedProps
                                            .paymentLabel
                                            ? _c(
                                                "v-card-text",
                                                {
                                                  attrs: {
                                                    dark: "",
                                                    color: "white"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "row align-center justify-end white--text"
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.selectedEvent
                                                              .extendedProps
                                                              .paymentLabel
                                                          ) + ":"
                                                        )
                                                      ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "display-1 font-weight-light ml-12"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm
                                                                  .selectedEvent
                                                                  .extendedProps
                                                                  .paymentValue
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "secondary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.editSubscription(
                                            _vm.selectedEvent.extendedProps
                                              .subscription_id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("mdi-pencil")]),
                                      _vm._v(
                                        "\n                  Изменить\n                "
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "secondary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.createPayment(
                                            _vm.selectedEvent.extendedProps
                                              .subscription_id,
                                            _vm.selectedEvent.extendedProps
                                              .clientId
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("mdi-cash")]),
                                      _vm._v(
                                        "\n                  Внести оплату\n                "
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "secondary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.prolongSubscription(
                                            _vm.selectedEvent.extendedProps
                                              .subscription_id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("mdi-page-next")]),
                                      _vm._v(
                                        "\n                  Продлить\n                "
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "secondary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.createDopSubscription(
                                            _vm.selectedEvent.extendedProps
                                              .clientId,
                                            _vm.selectedEvent.extendedProps
                                              .subscription_id,
                                            _vm.selectedEvent.extendedProps
                                              .timetable_id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-file-replace-outline")
                                      ]),
                                      _vm._v(
                                        "\n                  Допзанятия\n                "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "min-width": "250px",
                            "max-width": "500px",
                            "close-on-content-click": false,
                            "close-on-click": false,
                            activator: _vm.clientElement,
                            "full-width": "",
                            "offset-y": ""
                          },
                          model: {
                            value: _vm.clientOpen,
                            callback: function($$v) {
                              _vm.clientOpen = $$v
                            },
                            expression: "clientOpen"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: { color: "ma-0 grey lighten-4", flat: "" }
                            },
                            [
                              _c(
                                "v-toolbar",
                                { attrs: { color: "primary", dark: "" } },
                                [
                                  _c("v-toolbar-title", [
                                    _vm._v(
                                      _vm._s(_vm.selectedClient.title) +
                                        " #" +
                                        _vm._s(_vm.selectedClient.id)
                                    )
                                  ]),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.clientOpen = false
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-close")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-card-text", [
                                _vm.selectedClient.extendedProps.birthday
                                  ? _c("div", [
                                      _c("strong", [_vm._v("Дата рождения:")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectedClient.extendedProps
                                              .birthday
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.selectedClient.extendedProps.parents
                                  ? _c("div", [
                                      _c("strong", [
                                        _vm._v("ФИО родителя/опекуна:")
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectedClient.extendedProps
                                              .parents
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.selectedClient.extendedProps.email
                                  ? _c("div", [
                                      _c("strong", [_vm._v("Email:")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectedClient.extendedProps
                                              .email
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.selectedClient.extendedProps.phone
                                  ? _c("div", [
                                      _c("strong", [_vm._v("Телефон:")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectedClient.extendedProps
                                              .phone
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.selectedClient.extendedProps.birthday
                                  ? _c("div", [
                                      _c("strong", [_vm._v("Дата рождения:")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.selectedClient.extendedProps
                                              .birthday
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.selectedClient.extendedProps.timetables
                                  ? _c(
                                      "div",
                                      [
                                        _c("strong", { staticClass: "mr-1" }, [
                                          _vm._v("Группы:")
                                        ]),
                                        _vm._l(
                                          _vm.selectedClient.extendedProps
                                            .timetables,
                                          function(t) {
                                            return _c(
                                              "v-chip",
                                              {
                                                key: t.id,
                                                staticClass:
                                                  "group-chip d-block mx-5",
                                                attrs: {
                                                  color: "primary",
                                                  title: t.name + " #" + t.id
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(t.description) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "secondary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.editClient(
                                            _vm.selectedClient.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-badge-account")
                                      ]),
                                      _vm._v(
                                        "\n                  Просмотр клиента\n                "
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "secondary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.createSubscription(
                                            _vm.selectedClient.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [_vm._v("mdi-cash")]),
                                      _vm._v(
                                        "\n                  Создать абонемент\n                "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500" },
                  model: {
                    value: _vm.clientDialog,
                    callback: function($$v) {
                      _vm.clientDialog = $$v
                    },
                    expression: "clientDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(
                            "\n            Оплата абонемента #" +
                              _vm._s(
                                _vm.selectedEvent.extendedProps.subscription_id
                              ) +
                              " " +
                              _vm._s(
                                _vm.selectedEvent.extendedProps.clientName
                              ) +
                              "\n\n            "
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            {
                              attrs: { "aria-label": "Close" },
                              on: {
                                click: function($event) {
                                  _vm.clientDialog = false
                                }
                              }
                            },
                            [_vm._v("\n              mdi-close\n            ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "body-1 text-center" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      staticClass: "mt-5",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Сумма",
                                          "error-messages": _vm.fieldError(
                                            "payment"
                                          )
                                        },
                                        model: {
                                          value: _vm.payment.payment,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.payment,
                                              "payment",
                                              $$v
                                            )
                                          },
                                          expression: "payment.payment"
                                        }
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Примечание",
                                          "error-messages": _vm.fieldError(
                                            "comment"
                                          )
                                        },
                                        model: {
                                          value: _vm.payment.comment,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.payment,
                                              "comment",
                                              $$v
                                            )
                                          },
                                          expression: "payment.comment"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: {
                                color: "secondary",
                                rounded: "",
                                "min-width": "100",
                                disabled: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.savePayment()
                                }
                              }
                            },
                            [_vm._v("\n              Сохранить\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500" },
                  model: {
                    value: _vm.subscriptionDialog,
                    callback: function($$v) {
                      _vm.subscriptionDialog = $$v
                    },
                    expression: "subscriptionDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(
                            "\n            Абонемент " +
                              _vm._s(
                                _vm.selectedEvent.extendedProps.clientName
                              ) +
                              "\n            "
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            {
                              attrs: { "aria-label": "Close" },
                              on: {
                                click: function($event) {
                                  _vm.subscriptionDialog = false
                                }
                              }
                            },
                            [_vm._v("\n              mdi-close\n            ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "body-1 text-center pt-0" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "12" }
                                },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      staticClass: "mt-0",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0",
                                              attrs: { cols: "12", md: "12" }
                                            },
                                            [
                                              _c("v-select", {
                                                staticClass: "pt-0",
                                                attrs: {
                                                  label: "Шаблон абонемента",
                                                  items: _vm.templates,
                                                  "item-value": "id",
                                                  "item-text": "name",
                                                  "return-object": ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.onTemplateChange(
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.selectedTemplate,
                                                  callback: function($$v) {
                                                    _vm.selectedTemplate = $$v
                                                  },
                                                  expression: "selectedTemplate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  label: "Стоимость *",
                                                  "error-messages": _vm.fieldError(
                                                    "price"
                                                  )
                                                },
                                                model: {
                                                  value: _vm.subscription.price,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.subscription,
                                                      "price",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "subscription.price"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  label: "Число занятий",
                                                  "error-messages": _vm.fieldError(
                                                    "lessons_count"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.subscription
                                                      .lessons_count,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.subscription,
                                                      "lessons_count",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "subscription.lessons_count"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  ref: "startDateMenu",
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "return-value":
                                                      _vm.startDate,
                                                    transition:
                                                      "scale-transition",
                                                    "min-width": "290px",
                                                    "offset-y": ""
                                                  },
                                                  on: {
                                                    "update:returnValue": function(
                                                      $event
                                                    ) {
                                                      _vm.startDate = $event
                                                    },
                                                    "update:return-value": function(
                                                      $event
                                                    ) {
                                                      _vm.startDate = $event
                                                    }
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  readonly: "",
                                                                  value: _vm.formatDate(
                                                                    _vm
                                                                      .subscription
                                                                      .start_date
                                                                  ),
                                                                  label: "с *",
                                                                  "error-messages": _vm.fieldError(
                                                                    "start_date"
                                                                  ),
                                                                  color:
                                                                    "secondary",
                                                                  "prepend-icon":
                                                                    "mdi-calendar-outline",
                                                                  messages:
                                                                    "Дата начала действия абонемента"
                                                                }
                                                              },
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ]),
                                                  model: {
                                                    value: _vm.startDateMenu,
                                                    callback: function($$v) {
                                                      _vm.startDateMenu = $$v
                                                    },
                                                    expression: "startDateMenu"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-date-picker",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                        locale: "ru-ru",
                                                        landscape: "",
                                                        scrollable: "",
                                                        "first-day-of-week": "1"
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          _vm.startDateMenu = false
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.subscription
                                                            .start_date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "start_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "subscription.start_date"
                                                      }
                                                    },
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                            large: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.startDateMenu = false
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            Cancel\n                          "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  ref: "endDateMenu",
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "return-value": _vm.endDate,
                                                    transition:
                                                      "scale-transition",
                                                    "min-width": "290px",
                                                    "offset-y": ""
                                                  },
                                                  on: {
                                                    "update:returnValue": function(
                                                      $event
                                                    ) {
                                                      _vm.endDate = $event
                                                    },
                                                    "update:return-value": function(
                                                      $event
                                                    ) {
                                                      _vm.endDate = $event
                                                    }
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  readonly: "",
                                                                  value: _vm.formatDate(
                                                                    _vm
                                                                      .subscription
                                                                      .end_date
                                                                  ),
                                                                  label: "по *",
                                                                  "error-messages": _vm.fieldError(
                                                                    "end_date"
                                                                  ),
                                                                  color:
                                                                    "secondary",
                                                                  "prepend-icon":
                                                                    "mdi-calendar-outline",
                                                                  messages:
                                                                    "Дата окончания действия абонемента (включительно)"
                                                                }
                                                              },
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ]),
                                                  model: {
                                                    value: _vm.endDateMenu,
                                                    callback: function($$v) {
                                                      _vm.endDateMenu = $$v
                                                    },
                                                    expression: "endDateMenu"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-date-picker",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                        locale: "ru-ru",
                                                        landscape: "",
                                                        scrollable: "",
                                                        "first-day-of-week": "1"
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          _vm.endDateMenu = false
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.subscription
                                                            .end_date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "end_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "subscription.end_date"
                                                      }
                                                    },
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                            large: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.endDateMenu = false
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            Отмена\n                          "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  taggable: "",
                                                  label: "Группа по расписанию",
                                                  items: _vm.clientTimetables,
                                                  "error-messages": _vm.fieldError(
                                                    "timetable_id"
                                                  ),
                                                  "item-value": "id",
                                                  "item-text": "name",
                                                  "no-data-text":
                                                    "Укажите группы у клиента, чтобы добавить их к абонементу для корректного учета посещений занятий"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.onTimetableChange(
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.subscription
                                                      .timetable_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.subscription,
                                                      "timetable_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "subscription.timetable_id"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0",
                                              attrs: { cols: "12", md: "12" }
                                            },
                                            [
                                              _c("v-textarea", {
                                                staticClass: "pt-0",
                                                attrs: {
                                                  "error-messages": _vm.fieldError(
                                                    "comment"
                                                  ),
                                                  rows: "1",
                                                  "auto-grow": "",
                                                  label: "Комментарий"
                                                },
                                                model: {
                                                  value:
                                                    _vm.subscription.comment,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.subscription,
                                                      "comment",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "subscription.comment"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.lessonsByDates.length > 0
                                            ? _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Занятий по расписанию в выбранный период: "
                                                  ),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.lessonsByDates
                                                          .length
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.lessonsByDates.length > 0
                                            ? _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.lessonsByDates,
                                                  function(lesson) {
                                                    return _c(
                                                      "v-chip",
                                                      {
                                                        key: lesson,
                                                        attrs: { small: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(lesson) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: {
                                rounded: "",
                                color: "secondary",
                                "min-width": "100",
                                disabled: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveSubscription()
                                }
                              }
                            },
                            [_vm._v("\n              Сохранить\n            ")]
                          ),
                          _vm.subscription.id
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    rounded: "",
                                    color: "error",
                                    "min-width": "100",
                                    disabled: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeSubscription()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Удалить\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500" },
                  model: {
                    value: _vm.dopSubscriptionDialog,
                    callback: function($$v) {
                      _vm.dopSubscriptionDialog = $$v
                    },
                    expression: "dopSubscriptionDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(
                            "\n            Создать абонемент " +
                              _vm._s(
                                _vm.selectedEvent.extendedProps.clientName
                              ) +
                              "\n            "
                          ),
                          _c("div", { staticClass: "subtitle-2" }, [
                            _vm._v(
                              "\n              для компенсации пропущенных занятий\n            "
                            )
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-icon",
                            {
                              attrs: { "aria-label": "Close" },
                              on: {
                                click: function($event) {
                                  _vm.dopSubscriptionDialog = false
                                }
                              }
                            },
                            [_vm._v("\n              mdi-close\n            ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "body-1 text-center pt-0" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0",
                                  attrs: { cols: "12", md: "12" }
                                },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      staticClass: "mt-0",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  label: "Число занятий",
                                                  "error-messages": _vm.dopFieldError(
                                                    "lessons_count"
                                                  ),
                                                  messages:
                                                    "Кол-во зантий в исходном абонементе будет убавлено на данное кол-во"
                                                },
                                                model: {
                                                  value:
                                                    _vm.dopSubscription
                                                      .lessons_count,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.dopSubscription,
                                                      "lessons_count",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dopSubscription.lessons_count"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  ref: "startDateMenu",
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "return-value":
                                                      _vm.dopStartDate,
                                                    transition:
                                                      "scale-transition",
                                                    "min-width": "290px",
                                                    "offset-y": ""
                                                  },
                                                  on: {
                                                    "update:returnValue": function(
                                                      $event
                                                    ) {
                                                      _vm.dopStartDate = $event
                                                    },
                                                    "update:return-value": function(
                                                      $event
                                                    ) {
                                                      _vm.dopStartDate = $event
                                                    }
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  readonly: "",
                                                                  value: _vm.formatDate(
                                                                    _vm
                                                                      .dopSubscription
                                                                      .start_date
                                                                  ),
                                                                  label: "с *",
                                                                  "error-messages": _vm.dopFieldError(
                                                                    "start_date"
                                                                  ),
                                                                  color:
                                                                    "secondary",
                                                                  "prepend-icon":
                                                                    "mdi-calendar-outline",
                                                                  messages:
                                                                    "Дата начала действия абонемента"
                                                                }
                                                              },
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ]),
                                                  model: {
                                                    value: _vm.dopStartDateMenu,
                                                    callback: function($$v) {
                                                      _vm.dopStartDateMenu = $$v
                                                    },
                                                    expression:
                                                      "dopStartDateMenu"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-date-picker",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                        locale: "ru-ru",
                                                        landscape: "",
                                                        scrollable: "",
                                                        "first-day-of-week": "1"
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          _vm.dopStartDateMenu = false
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.dopSubscription
                                                            .start_date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.dopSubscription,
                                                            "start_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "dopSubscription.start_date"
                                                      }
                                                    },
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                            large: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.dopStartDateMenu = false
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            Cancel\n                          "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  ref: "endDateMenu",
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "return-value":
                                                      _vm.dopEndDate,
                                                    transition:
                                                      "scale-transition",
                                                    "min-width": "290px",
                                                    "offset-y": ""
                                                  },
                                                  on: {
                                                    "update:returnValue": function(
                                                      $event
                                                    ) {
                                                      _vm.dopEndDate = $event
                                                    },
                                                    "update:return-value": function(
                                                      $event
                                                    ) {
                                                      _vm.dopEndDate = $event
                                                    }
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  readonly: "",
                                                                  value: _vm.formatDate(
                                                                    _vm
                                                                      .dopSubscription
                                                                      .end_date
                                                                  ),
                                                                  label: "по *",
                                                                  "error-messages": _vm.dopFieldError(
                                                                    "end_date"
                                                                  ),
                                                                  color:
                                                                    "secondary",
                                                                  "prepend-icon":
                                                                    "mdi-calendar-outline",
                                                                  messages:
                                                                    "Дата окончания действия абонемента (включительно)"
                                                                }
                                                              },
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ]),
                                                  model: {
                                                    value: _vm.dopEndDateMenu,
                                                    callback: function($$v) {
                                                      _vm.dopEndDateMenu = $$v
                                                    },
                                                    expression: "dopEndDateMenu"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-date-picker",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                        locale: "ru-ru",
                                                        landscape: "",
                                                        scrollable: "",
                                                        "first-day-of-week": "1"
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          _vm.dopEndDateMenu = false
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.dopSubscription
                                                            .end_date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.dopSubscription,
                                                            "end_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "dopSubscription.end_date"
                                                      }
                                                    },
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                            large: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.dopEndDateMenu = false
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            Отмена\n                          "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  taggable: "",
                                                  label: "Группа по расписанию",
                                                  items: _vm.clientTimetables,
                                                  "error-messages": _vm.dopFieldError(
                                                    "timetable_id"
                                                  ),
                                                  "item-value": "id",
                                                  "item-text": "name",
                                                  "no-data-text":
                                                    "Укажите группы у клиента, чтобы добавить их к абонементу для корректного учета посещений занятий"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.onDopTimetableChange(
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.dopSubscription
                                                      .timetable_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.dopSubscription,
                                                      "timetable_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dopSubscription.timetable_id"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pt-0",
                                              attrs: { cols: "12", md: "12" }
                                            },
                                            [
                                              _c("v-textarea", {
                                                staticClass: "pt-0",
                                                attrs: {
                                                  "error-messages": _vm.dopFieldError(
                                                    "comment"
                                                  ),
                                                  rows: "1",
                                                  "auto-grow": "",
                                                  label: "Комментарий"
                                                },
                                                model: {
                                                  value:
                                                    _vm.dopSubscription.comment,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.dopSubscription,
                                                      "comment",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "dopSubscription.comment"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.dopLessonsByDates.length > 0
                                            ? _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Занятий по расписанию в выбранный период: "
                                                  ),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.dopLessonsByDates
                                                          .length
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.dopLessonsByDates.length > 0
                                            ? _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dopLessonsByDates,
                                                  function(lesson) {
                                                    return _c(
                                                      "v-chip",
                                                      {
                                                        key: lesson,
                                                        attrs: { small: "" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(lesson) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: {
                                rounded: "",
                                color: "secondary",
                                "min-width": "100",
                                disabled: _vm.loading
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveDopSubscription()
                                }
                              }
                            },
                            [_vm._v("\n              Сохранить\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }