<template>
  <v-container
    id="calendar"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="mx-auto"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>
        <v-card>
          <v-toolbar flat>
            <v-sheet>
              <v-btn-toggle
                color="secondary"
              />
            </v-sheet>

            <v-btn
              color="secondary"
              @click="prev()"
            >
              <v-icon>
                mdi-chevron-left
              </v-icon>
            </v-btn>

            <v-btn
              color="secondary"
              @click="next()"
            >
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-btn>

            <v-spacer />

            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <v-spacer />

            <v-btn
              color="secondary"
              @click="today()"
            >
              Сегодня
            </v-btn>
          </v-toolbar>

          <v-row
            class="ml-2 mr-2"
          >
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Поиск по фамилии, номеру телефона"
                single-line
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-select
                v-model="timetable"
                label="Группа"
                :items="timetables"
                item-value="id"
                item-text="name"
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-select
                v-model="stateFilter"
                color="secondary"
                item-color="secondary"
                label="Статус"
                item-value="tag"
                item-text="name"
                multiple
                :items="states"
              >
                <template v-slot:item="{ attrs, item, on }">
                  <v-list-item
                    v-bind="attrs"
                    active-class="secondary elevation-4 white--text"
                    class="mx-3 mb-2 v-sheet"
                    elevation="0"
                    v-on="on"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        item-value="item.id"
                        return-object
                        v-text="item.name"
                      />
                    </v-list-item-content>

                    <v-scale-transition>
                      <v-list-item-icon
                        v-if="attrs.inputValue"
                        class="my-3"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-list-item-icon>
                    </v-scale-transition>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-select
                v-model="resultFilter"
                color="secondary"
                item-color="secondary"
                label="Причина отказа"
                item-value="tag"
                item-text="name"
                multiple
                :items="results"
              >
                <template v-slot:item="{ attrs, item, on }">
                  <v-list-item
                    v-bind="attrs"
                    active-class="secondary elevation-4 white--text"
                    class="mx-3 mb-2 v-sheet"
                    elevation="0"
                    v-on="on"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        item-value="item.id"
                        return-object
                        v-text="item.name"
                      />
                    </v-list-item-content>

                    <v-scale-transition>
                      <v-list-item-icon
                        v-if="attrs.inputValue"
                        class="my-3"
                      >
                        <v-icon>mdi-check</v-icon>
                      </v-list-item-icon>
                    </v-scale-transition>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col
              cols="12"
              md="2"
            >
              <v-switch
                v-model="onlyWithEvents"
                :label="resourcesWithEventsCount"
              />
            </v-col>
          </v-row>

          <v-sheet
            flat
          >
            <full-calendar
              ref="fullCalendar"
              height="auto"
              class="demo-app-calendar"
              lang="ru"
              resource-area-width="180px"
              scheduler-license-key="GPL-My-Project-Is-Open-Source"
              default-view="resourceTimelineMonth"
              :header="{
                left: '',
                center: '',
                right: ''
              }"
              slot-width="20"
              resource-label-text="ФИО"
              resource-order="title"
              :filter-resources-with-events="onlyWithEvents"
              :plugins="calendarPlugins"
              :weekends="calendarWeekends"
              :slot-label-format="slotLabelFormat"
              :locale="locale"
              :events="events"
              :resources="filteredResources"
              :resource-render="resourceRender"
              :event-data-transform="eventDataTransform"
              @eventClick="eventClick"
              @dateClick="dateClick"
            />
            <v-menu
              v-model="selectedOpen"
              min-width="350px"
              :close-on-content-click="false"
              :close-on-click="false"
              :activator="selectedElement"
              full-width
              offset-y
            >
              <v-card
                color="ma-0 grey lighten-4"
                flat
              >
                <v-toolbar
                  :color="selectedEvent.extendedProps.colorClass"
                  dark
                >
                  <v-toolbar-title>
                    {{ selectedEvent.start | formatDate }} &mdash; {{ minusDay(selectedEvent.end) | formatDate }}
                  </v-toolbar-title>
                  <v-spacer />
                  <v-btn
                    icon
                    @click="selectedOpen = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <div v-if="selectedEvent.extendedProps.groupLabel">
                    <strong>{{ selectedEvent.extendedProps.groupLabel }}:</strong>
                    <v-chip
                      small
                      class="ma-2"
                      outlined
                    >
                      {{ selectedEvent.extendedProps.groupValue }}
                    </v-chip>
                  </div>
                  <div v-if="selectedEvent.extendedProps.paymentLabel">
                    <strong>{{ selectedEvent.extendedProps.paymentLabel }}:</strong>
                    <v-chip
                      small
                      class="ma-2"
                      :color="selectedEvent.extendedProps.colorClass"
                    >
                      {{ selectedEvent.extendedProps.paymentValue }}
                    </v-chip>
                  </div>
                  <div v-if="selectedEvent.extendedProps.countLabel">
                    <strong>{{ selectedEvent.extendedProps.countLabel }}:</strong>

                    <v-progress-linear
                      :value="selectedEvent.extendedProps.lessonsProgress"
                      color="blue-grey"
                      height="25"
                      reactive
                    >
                      <template v-slot="{ value }">
                        <strong>{{ selectedEvent.extendedProps.countValue }}</strong>
                      </template>
                    </v-progress-linear>
                  </div>

                  <base-material-card
                    v-if="selectedEvent.extendedProps.payments && selectedEvent.extendedProps.payments.length > 0"
                    color="success"
                    dark
                    icon="mdi-cash"
                    inline
                    title="Оплаты абонемента"
                    class="px-5 py-3"
                  >
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Дата</th>
                          <th>Сумма</th>
                          <th />
                          <th class="text-right" />
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="item in selectedEvent.extendedProps.payments"
                          :key="item.id"
                        >
                          <td>{{ item.id }}</td>
                          <td>{{ item.created_date | formatDateFromUnixTime }}</td>
                          <td>{{ item.payment }}</td>
                          <td>{{ item.comment }}</td>
                          <td class="text-right">
                          <!-- <v-btn
                            color="success"
                            class="px-2 ml-1"
                            min-width="0"
                            small
                            @click="loadPayment(item.id)"
                          >
                            <v-icon
                              small
                            >
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                          <v-btn
                            color="error"
                            class="px-2 ml-1"
                            min-width="0"
                            small
                          >
                            <v-icon
                              small
                            >
                              mdi-close
                            </v-icon>
                          </v-btn> -->
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <v-card-text
                      v-if="selectedEvent.extendedProps.paymentLabel"
                      dark
                      color="white"
                    >
                      <div class="row align-center justify-end white--text">
                        <strong>{{ selectedEvent.extendedProps.paymentLabel }}:</strong>
                        <div class="display-1 font-weight-light ml-12">
                          {{ selectedEvent.extendedProps.paymentValue }}
                        </div>
                      </div>
                    </v-card-text>
                  </base-material-card>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    text
                    color="secondary"
                    @click="editSubscription(selectedEvent.extendedProps.subscription_id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                    Изменить
                  </v-btn>
                  <v-btn
                    text
                    color="secondary"
                    @click="createPayment(selectedEvent.extendedProps.subscription_id, selectedEvent.extendedProps.clientId)"
                  >
                    <v-icon>mdi-cash</v-icon>
                    Внести оплату
                  </v-btn>
                  <v-btn
                    text
                    color="secondary"
                    @click="prolongSubscription(selectedEvent.extendedProps.subscription_id)"
                  >
                    <v-icon>mdi-page-next</v-icon>
                    Продлить
                  </v-btn>
                  <v-btn
                    text
                    color="secondary"
                    @click="createDopSubscription(selectedEvent.extendedProps.clientId, selectedEvent.extendedProps.subscription_id, selectedEvent.extendedProps.timetable_id)"
                  >
                    <v-icon>mdi-file-replace-outline</v-icon>
                    Допзанятия
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>

            <v-menu
              v-model="clientOpen"
              min-width="250px"
              max-width="500px"
              :close-on-content-click="false"
              :close-on-click="false"
              :activator="clientElement"
              full-width
              offset-y
            >
              <v-card
                color="ma-0 grey lighten-4"
                flat
              >
                <v-toolbar
                  color="primary"
                  dark
                >
                  <v-toolbar-title>{{ selectedClient.title }} #{{ selectedClient.id }}</v-toolbar-title>
                  <v-spacer />
                  <v-btn
                    icon
                    @click="clientOpen = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <div v-if="selectedClient.extendedProps.birthday">
                    <strong>Дата рождения:</strong> {{ selectedClient.extendedProps.birthday }}
                  </div>
                  <div v-if="selectedClient.extendedProps.parents">
                    <strong>ФИО родителя/опекуна:</strong> {{ selectedClient.extendedProps.parents }}
                  </div>
                  <div v-if="selectedClient.extendedProps.email">
                    <strong>Email:</strong> {{ selectedClient.extendedProps.email }}
                  </div>
                  <div v-if="selectedClient.extendedProps.phone">
                    <strong>Телефон:</strong> {{ selectedClient.extendedProps.phone }}
                  </div>
                  <div v-if="selectedClient.extendedProps.birthday">
                    <strong>Дата рождения:</strong> {{ selectedClient.extendedProps.birthday }}
                  </div>
                  <div v-if="selectedClient.extendedProps.timetables">
                    <strong class="mr-1">Группы:</strong>
                    <v-chip
                      v-for="t in selectedClient.extendedProps.timetables"
                      :key="t.id"
                      class="group-chip d-block mx-5"
                      color="primary"
                      :title="`${t.name} #${t.id}`"
                    >
                      {{ t.description }}
                    </v-chip>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    text
                    color="secondary"
                    @click="editClient(selectedClient.id)"
                  >
                    <v-icon>mdi-badge-account</v-icon>
                    Просмотр клиента
                  </v-btn>
                  <v-btn
                    text
                    color="secondary"
                    @click="createSubscription(selectedClient.id)"
                  >
                    <v-icon>mdi-cash</v-icon>
                    Создать абонемент
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-card>

        <v-dialog
          v-model="clientDialog"
          max-width="500"
        >
          <v-card>
            <v-card-title>
              Оплата абонемента #{{ selectedEvent.extendedProps.subscription_id }} {{ selectedEvent.extendedProps.clientName }}

              <v-spacer />

              <v-icon
                aria-label="Close"
                @click="clientDialog = false"
              >
                mdi-close
              </v-icon>
            </v-card-title>

            <v-card-text class="body-1 text-center">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-form
                    ref="form"
                    class="mt-5"
                    lazy-validation
                  >
                    <v-text-field
                      v-model="payment.payment"
                      required
                      label="Сумма"
                      :error-messages="fieldError('payment')"
                    />

                    <v-text-field
                      v-model="payment.comment"
                      label="Примечание"
                      :error-messages="fieldError('comment')"
                    />
                  </v-form>
                </v-col>
              </v-row>

              <v-btn
                class="ma-1"
                color="secondary"
                rounded
                min-width="100"
                :disabled="loading"
                @click="savePayment()"
              >
                Сохранить
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="subscriptionDialog"
          max-width="500"
        >
          <v-card>
            <v-card-title>
              Абонемент {{ selectedEvent.extendedProps.clientName }}
              <v-spacer />

              <v-icon
                aria-label="Close"
                @click="subscriptionDialog = false"
              >
                mdi-close
              </v-icon>
            </v-card-title>

            <v-card-text class="body-1 text-center pt-0">
              <v-row>
                <v-col
                  class="pt-0"
                  cols="12"
                  md="12"
                >
                  <v-form
                    ref="form"
                    class="mt-0"
                    lazy-validation
                  >
                    <v-row>
                      <v-col
                        class="pt-0"
                        cols="12"
                        md="12"
                      >
                        <v-select
                          v-model="selectedTemplate"
                          class="pt-0"
                          label="Шаблон абонемента"
                          :items="templates"
                          item-value="id"
                          item-text="name"
                          return-object
                          @change="onTemplateChange($event)"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="subscription.price"
                          required
                          label="Стоимость *"
                          :error-messages="fieldError('price')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="subscription.lessons_count"
                          required
                          label="Число занятий"
                          :error-messages="fieldError('lessons_count')"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-menu
                          ref="startDateMenu"
                          v-model="startDateMenu"
                          :close-on-content-click="false"
                          :return-value.sync="startDate"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              readonly
                              :value="formatDate(subscription.start_date)"
                              label="с *"
                              :error-messages="fieldError('start_date')"
                              color="secondary"
                              prepend-icon="mdi-calendar-outline"
                              messages="Дата начала действия абонемента"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="subscription.start_date"
                            color="secondary"
                            locale="ru-ru"
                            landscape
                            scrollable
                            first-day-of-week="1"
                            @input="startDateMenu = false"
                          >
                            <v-spacer />

                            <v-btn
                              color="secondary"
                              large
                              @click="startDateMenu = false"
                            >
                              Cancel
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-menu
                          ref="endDateMenu"
                          v-model="endDateMenu"
                          :close-on-content-click="false"
                          :return-value.sync="endDate"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              readonly
                              :value="formatDate(subscription.end_date)"
                              label="по *"
                              :error-messages="fieldError('end_date')"
                              color="secondary"
                              prepend-icon="mdi-calendar-outline"
                              messages="Дата окончания действия абонемента (включительно)"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="subscription.end_date"
                            color="secondary"
                            locale="ru-ru"
                            landscape
                            scrollable
                            first-day-of-week="1"
                            @input="endDateMenu = false"
                          >
                            <v-spacer />

                            <v-btn
                              color="secondary"
                              large
                              @click="endDateMenu = false"
                            >
                              Отмена
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-select
                          v-model="subscription.timetable_id"
                          taggable
                          label="Группа по расписанию"
                          :items="clientTimetables"
                          :error-messages="fieldError('timetable_id')"
                          item-value="id"
                          item-text="name"
                          no-data-text="Укажите группы у клиента, чтобы добавить их к абонементу для корректного учета посещений занятий"
                          @change="onTimetableChange($event)"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        class="pt-0"
                      >
                        <v-textarea
                          v-model="subscription.comment"
                          class="pt-0"
                          :error-messages="fieldError('comment')"
                          rows="1"
                          auto-grow
                          label="Комментарий"
                        />
                      </v-col>
                      <v-col
                        v-if="lessonsByDates.length > 0"
                        cols="12"
                        md="12"
                      >
                        Занятий по расписанию в выбранный период: <strong>{{ lessonsByDates.length }}</strong>
                      </v-col>
                      <v-col
                        v-if="lessonsByDates.length > 0"
                        cols="12"
                        md="12"
                      >
                        <v-chip
                          v-for="lesson in lessonsByDates"
                          :key="lesson"
                          small
                        >
                          {{ lesson }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>

              <v-btn
                class="ma-1"
                rounded
                color="secondary"
                min-width="100"
                :disabled="loading"
                @click="saveSubscription()"
              >
                Сохранить
              </v-btn>

              <v-btn
                v-if="subscription.id"
                class="ma-1"
                rounded
                color="error"
                min-width="100"
                :disabled="loading"
                @click="removeSubscription()"
              >
                Удалить
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dopSubscriptionDialog"
          max-width="500"
        >
          <v-card>
            <v-card-title>
              Создать абонемент {{ selectedEvent.extendedProps.clientName }}
              <div
                class="subtitle-2"
              >
                для компенсации пропущенных занятий
              </div>
              <v-spacer />

              <v-icon
                aria-label="Close"
                @click="dopSubscriptionDialog = false"
              >
                mdi-close
              </v-icon>
            </v-card-title>

            <v-card-text class="body-1 text-center pt-0">
              <v-row>
                <v-col
                  class="pt-0"
                  cols="12"
                  md="12"
                >
                  <v-form
                    ref="form"
                    class="mt-0"
                    lazy-validation
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="dopSubscription.lessons_count"
                          required
                          label="Число занятий"
                          :error-messages="dopFieldError('lessons_count')"
                          messages="Кол-во зантий в исходном абонементе будет убавлено на данное кол-во"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-menu
                          ref="startDateMenu"
                          v-model="dopStartDateMenu"
                          :close-on-content-click="false"
                          :return-value.sync="dopStartDate"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              readonly
                              :value="formatDate(dopSubscription.start_date)"
                              label="с *"
                              :error-messages="dopFieldError('start_date')"
                              color="secondary"
                              prepend-icon="mdi-calendar-outline"
                              messages="Дата начала действия абонемента"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="dopSubscription.start_date"
                            color="secondary"
                            locale="ru-ru"
                            landscape
                            scrollable
                            first-day-of-week="1"
                            @input="dopStartDateMenu = false"
                          >
                            <v-spacer />

                            <v-btn
                              color="secondary"
                              large
                              @click="dopStartDateMenu = false"
                            >
                              Cancel
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-menu
                          ref="endDateMenu"
                          v-model="dopEndDateMenu"
                          :close-on-content-click="false"
                          :return-value.sync="dopEndDate"
                          transition="scale-transition"
                          min-width="290px"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              readonly
                              :value="formatDate(dopSubscription.end_date)"
                              label="по *"
                              :error-messages="dopFieldError('end_date')"
                              color="secondary"
                              prepend-icon="mdi-calendar-outline"
                              messages="Дата окончания действия абонемента (включительно)"
                              v-on="on"
                            />
                          </template>

                          <v-date-picker
                            v-model="dopSubscription.end_date"
                            color="secondary"
                            locale="ru-ru"
                            landscape
                            scrollable
                            first-day-of-week="1"
                            @input="dopEndDateMenu = false"
                          >
                            <v-spacer />

                            <v-btn
                              color="secondary"
                              large
                              @click="dopEndDateMenu = false"
                            >
                              Отмена
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-select
                          v-model="dopSubscription.timetable_id"
                          taggable
                          label="Группа по расписанию"
                          :items="clientTimetables"
                          :error-messages="dopFieldError('timetable_id')"
                          item-value="id"
                          item-text="name"
                          no-data-text="Укажите группы у клиента, чтобы добавить их к абонементу для корректного учета посещений занятий"
                          @change="onDopTimetableChange($event)"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        class="pt-0"
                      >
                        <v-textarea
                          v-model="dopSubscription.comment"
                          class="pt-0"
                          :error-messages="dopFieldError('comment')"
                          rows="1"
                          auto-grow
                          label="Комментарий"
                        />
                      </v-col>
                      <v-col
                        v-if="dopLessonsByDates.length > 0"
                        cols="12"
                        md="12"
                      >
                        Занятий по расписанию в выбранный период: <strong>{{ dopLessonsByDates.length }}</strong>
                      </v-col>
                      <v-col
                        v-if="dopLessonsByDates.length > 0"
                        cols="12"
                        md="12"
                      >
                        <v-chip
                          v-for="lesson in dopLessonsByDates"
                          :key="lesson"
                          small
                        >
                          {{ lesson }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>

              <v-btn
                class="ma-1"
                rounded
                color="secondary"
                min-width="100"
                :disabled="loading"
                @click="saveDopSubscription()"
              >
                Сохранить
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
  import ruLocale from '@fullcalendar/core/locales/ru'
  import moment from 'moment'
  import clientsApi from '../services/ClientsApi'
  import subscriptionsApi from '../services/SubscriptionsApi'
  import timetableApi from '../services/TimetablesApi'
  import CRMStorage from '../services/CRMStorage'

  export default {
    filters: {
      formatDate: function (date) {
        return moment(date).format('DD.MM.YYYY')
      },
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('DD.MM.YYYY HH:mm')
      },
    },
    components: {
      FullCalendar, // make the <FullCalendar> tag available
    },
    props: ['clientId'],
    data: function () {
      return {
        eventsByDates: [],
        eventsByDatesStart: null,
        eventsByDatesEnd: null,
        eventDataTransform: function (event) {
          event.end = moment(event.end).add(1, 'days').format('YYYY-MM-DD')
          return event
        },
        title: null,
        onlyWithEvents: CRMStorage.getItem('SL_onlyWithEvents', false),
        calendarPlugins: [ // plugins must be defined in the JS
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          resourceTimelinePlugin,
        ],
        locale: ruLocale,
        calendarWeekends: true,
        calendarEvents: [ // initial event data
          { title: 'Event Now', start: new Date() },
        ],

        slotLabelFormat: [
          { weekday: 'short' },
          { day: 'numeric' },
        ],

        selectedEvent: { extendedProps: { colorClass: null, clientName: null } },
        selectedElement: null,
        selectedOpen: false,

        selectedClient: { id: null, extendedProps: {} },
        clientElement: null,
        clientOpen: false,

        resources: [],

        search: '',
        states: [],
        results: [],
        timetables: [{ id: null, name: ' - не выбрано - ', tag: null }],

        stateFilter: [],
        resultFilter: [],
        timetable: null,

        error: null,
        loading: false,
        clientDialog: false,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        payment: {
          bill: 0,
          payment: null,
          comment: null,
        },

        subscriptionDialog: false,
        startDateMenu: false,
        endDateMenu: false,
        startDate: '',
        endDate: '',
        invalidateCounter: 1,
        subscription: {
          id: null,
          client: { name: '' },
          start_date: null,
          end_date: null,
          lessons_count: null,
          price: null,
          timetable: null,
          timetable_id: null,
          lessons: [],
          payments: [],
        },

        templates: [],
        selectedTemplate: null,

        dopSubscriptionDialog: false,
        dopStartDateMenu: false,
        dopEndDateMenu: false,
        dopStartDate: '',
        dopEndDate: '',
        dopInvalidateCounter: 1,
        dopSubscription: {
          id: null,
          client: { name: '' },
          start_date: null,
          end_date: null,
          lessons_count: null,
          price: 0,
          timetable: null,
          timetable_id: null,
          lessons: [],
          payments: [],
        },
        dopSelectedTemplate: null,
        dopFieldsErrors: [],
        dopDonorSubscriptionId: null,
        dopFieldError (fieldName) {
          let errors = []
          if (this.dopFieldsErrors) {
            this.dopFieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
      }
    },
    computed: {
      clientTimetables () {
        console.log(this.selectedClient)
        var filtered = []
        let timetableIds = this.selectedClient.extendedProps.timetableIds
        if (timetableIds && timetableIds.length > 0) {
          for (var i = 0; i < this.timetables.length; i++) {
            if (timetableIds.includes('' + this.timetables[i].id)) {
              filtered.push(this.timetables[i])
            }
          }
          return filtered
        } else {
          return this.timetables
        }
      },
      filteredResources () {
        if (this.search || this.timetable || (this.stateFilter && this.stateFilter.length > 0) || (this.resultFilter && this.resultFilter.length > 0)) {
          var filtered = []
          for (var i = 0; i < this.resources.length; i++) {
            if (
              (!this.search || (this.search &&
              ((this.search.startsWith('#') && this.resources[i].id.toString() === this.search.substr(1)) ||
              (this.resources[i].title.toLowerCase().indexOf(this.search.toLowerCase()) >= 0) ||
              (this.resources[i].extendedProps.phone && this.resources[i].extendedProps.phone.toLowerCase().indexOf(this.search.toLowerCase()) >= 0) ||
              (this.resources[i].extendedProps.parents && this.resources[i].extendedProps.parents.toLowerCase().indexOf(this.search.toLowerCase()) >= 0)
              ))) &&
              (!this.timetable || (this.timetable && this.resources[i].extendedProps.timetableIds && this.resources[i].extendedProps.timetableIds.includes('' + this.timetable) > 0)) &&
              ((!this.stateFilter || this.stateFilter.length === 0) || (this.stateFilter && this.stateFilter.length > 0 && this.resources[i].extendedProps.state && this.stateFilter.includes(this.resources[i].extendedProps.state) > 0)) &&
              ((!this.resultFilter || this.resultFilter.length === 0) || (this.resultFilter && this.resultFilter.length > 0 && this.resources[i].extendedProps.result && this.resultFilter.includes(this.resources[i].extendedProps.result) > 0))
            ) {
              filtered.push(this.resources[i])
            }
          }
          return filtered
        }
        return this.resources
      },
      lessonsByDates () {
        return this.lessonsByDatesFilter(this.subscription)
      },
      dopLessonsByDates () {
        return this.lessonsByDatesFilter(this.dopSubscription)
      },
      resourcesWithEventsCount () {
        if (this.eventsByDates) {
          let resSet = new Set()
          const visibleEvents = this.eventsByDates.filter(event => {
            if (moment(event.start).toDate() > this.eventsByDatesEnd || moment(event.end).toDate() < this.eventsByDatesStart) return false
            return true
          })
          for (var i = 0; i < visibleEvents.length; i++) {
            resSet.add(visibleEvents[i].resourceId)
          }
          return 'Только с абонементами (' + resSet.size + ')'
        }
        return 'Только с абонементами'
      },
    },
    watch: {
      onlyWithEvents: function (val) {
        CRMStorage.setItem('SL_onlyWithEvents', val)
      },
    },
    mounted () {
      document.title = 'Абонементы | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      this.title = moment(this.$refs.fullCalendar.getApi().getDate()).format('MMMM YYYY')

      if (this.clientId) {
        this.search = '#' + this.clientId
        this.onlyWithEvents = false
        this.stateFilter = []
        this.resultFilter = []
        this.timetable = null
      }

      clientsApi.fetchClientsResources()
        .then(response => {
          this.resources = response
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })

      clientsApi
        .fetchClientStates()
        .then(response => {
          this.states = this.states.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })

      clientsApi
        .fetchClientResults()
        .then(response => {
          this.results = this.results.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })

      timetableApi
        .fetchActiveTimetables()
        .then(response => {
          this.timetables = this.timetables.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })

      subscriptionsApi
        .fetchSubscriptionTemplates()
        .then(response => {
          this.templates = response
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })
    },
    methods: {
      events: function (fetchInfo, successCallback, failureCallback) {
        this.eventsByDatesStart = fetchInfo.start
        this.eventsByDatesEnd = moment(fetchInfo.end).add(-1, 'seconds').toDate()
        let start = moment(fetchInfo.startStr).format('YYYY-MM-DD')
        let end = moment(fetchInfo.endStr).format('YYYY-MM-DD')
        subscriptionsApi.fetchSubscriptionsByDates(start, end)
          .then(response => {
            successCallback(response)
            this.eventsByDates = response
          })
          .catch(error => {
            failureCallback(error)
          })
      },
      next () {
        this.$refs.fullCalendar.getApi().next()
        this.title = moment(this.$refs.fullCalendar.getApi().getDate()).format('MMMM YYYY')
      },
      prev () {
        this.$refs.fullCalendar.getApi().prev()
        this.title = moment(this.$refs.fullCalendar.getApi().getDate()).format('MMMM YYYY')
      },
      today () {
        this.$refs.fullCalendar.getApi().today()
        this.title = moment(this.$refs.fullCalendar.getApi().getDate()).format('MMMM YYYY')
      },
      eventClick (info) {
        const open = () => {
          this.selectedEvent = info.event
          this.selectedElement = info.jsEvent.target
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }
      },

      resourceRender: function (info) {
        var self = this
        info.el.addEventListener('click', function (e) {
          const openClient = () => {
            self.selectedClient = info.resource
            self.clientElement = e.target
            setTimeout(() => self.clientOpen = true, 10)
          }

          if (self.clientOpen) {
            self.clientOpen = false
            setTimeout(openClient, 10)
          } else {
            openClient()
          }
        })
      },
      dateClick (arg) {
      },

      loadPayment (paymentId) {
        subscriptionsApi
          .fetchSubscriptionPayment(paymentId)
          .then(response => {
            this.payment = response
            this.clientDialog = true
          })
      },
      createPayment (subscriptionId, clientId) {
        this.payment = {}
        this.payment.bill = 0
        this.payment.client_id = clientId
        this.payment.subscription_id = subscriptionId
        this.clientDialog = true
      },
      savePayment () {
        this.loading = true
        this.error = null
        this.fieldsErrors = []

        if (this.payment.id) {
          subscriptionsApi
            .updateSubscriptionPayment(this.payment)
            .then(response => {
              this.payment = response
              this.clientDialog = false
              this.$refs.fullCalendar.getApi().refetchEvents()
              let itemIndex = this.selectedEvent.extendedProps.payments.findIndex(item => item.id === this.payment.id)
              this.selectedEvent.extendedProps.payments[itemIndex] = this.payment
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.$refs.fullCalendar.getApi().refetchEvents()
              this.loading = false
            })
        } else {
          subscriptionsApi
            .createSubscriptionPayment(this.payment)
            .then(response => {
              this.payment = response
              this.clientDialog = false
              this.$refs.fullCalendar.getApi().refetchEvents()
              this.selectedEvent.extendedProps.payments.push(this.payment)
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.$refs.fullCalendar.getApi().refetchEvents()
              this.loading = false
            })
        }
      },

      loadSubscription (subscriptionId) {
        subscriptionsApi
          .fetchSubscription(subscriptionId)
          .then(response => {
            this.subscription = response
            this.subscriptionDialog = true
          })
      },
      editSubscription (subscriptionId) {
        this.$router.push('/subscription/' + subscriptionId)
      },
      editClient (clientId) {
        this.$router.push('/client/' + clientId)
      },
      createSubscription (clientId) {
        this.dopSubscriptionDialog = false
        this.fieldsErrors = []
        this.subscription = {}
        this.subscription.student_id = clientId
        this.subscriptionDialog = true
        if (this.selectedClient.extendedProps.timetableIds && this.selectedClient.extendedProps.timetableIds.length === 1) {
          this.subscription.timetable_id = Number(this.selectedClient.extendedProps.timetableIds[0])
        }
      },

      createDopSubscription (clientId, subscriptionId, timetableId) {
        this.dopSubscription = {}
        this.dopSubscription.price = 0
        this.dopSubscription.student_id = clientId
        this.dopDonorSubscriptionId = subscriptionId
        this.dopSubscription.comment = '![Абонемент для компенсации пропущенных занятий по абонементу id=' + subscriptionId + ']'
        this.dopSubscription.timetable_id = timetableId
        this.dopSubscriptionDialog = true
        this.dopFieldsErrors = []
        if (this.selectedClient.extendedProps.timetableIds && this.selectedClient.extendedProps.timetableIds.length === 1) {
          this.dopSubscription.timetable_id = Number(this.selectedClient.extendedProps.timetableIds[0])
        }
      },

      saveDopSubscription () {
        this.loading = true
        subscriptionsApi
          .fetchSubscription(this.dopDonorSubscriptionId)
          .then(response => {
            let subscription = response
            let lessonsAvailable = subscription.lessons_count - subscription.lessons.length
            if (this.dopSubscription.lessons_count > lessonsAvailable) {
              this.dopFieldsErrors = [{ field: 'lessons_count', message: 'Вы не можете компенсировать занятий больше, чем осталось по абонементу. Занятий осталось: ' + lessonsAvailable }]
              this.loading = false
              return
            }

            subscriptionsApi
              .createSubscription(this.dopSubscription)
              .then(response => {
                this.dopSubscription = response
                this.dopSubscriptionDialog = false
                // Убавляем кол-во занятий в абонементе доноре
                subscription.lessons_count = subscription.lessons_count - this.dopSubscription.lessons_count
                subscriptionsApi
                  .updateSubscription(subscription)
                  .then(response => {
                    this.$router.push('/client/' + subscription.student_id)
                  })
                  .catch(error => {
                    let data = error.response.data
                    if (error.response.status === 422) {
                      this.dopFieldsErrors = data
                    } else {
                      this.error = this.pretty(data)
                    }
                    this.loading = false
                  })
              })
              .catch(error => {
                let data = error.response.data
                if (error.response.status === 422) {
                  this.dopFieldsErrors = data
                } else {
                  this.error = this.pretty(data)
                }
                this.loading = false
              })
          })
      },

      prolongSubscription (subscriptionId) {
        subscriptionsApi
          .fetchSubscription(subscriptionId)
          .then(response => {
            let lastSubscription = response
            this.subscription = {}
            this.subscription.student_id = lastSubscription.student_id
            this.subscription.timetable_id = lastSubscription.timetable_id
            this.subscription.lessons_count = lastSubscription.lessons_count
            this.subscription.price = lastSubscription.price

            if (lastSubscription.isCalendarMonthPeriod) {
              // Период абонемента соответствует календаному месяцу
              this.subscription.start_date = moment(lastSubscription.start_date).add(1, 'months').format('YYYY-MM-DD')
              this.subscription.end_date = moment(this.subscription.start_date).add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
            } else {
              this.subscription.start_date = moment(lastSubscription.end_date).add(1, 'days').format('YYYY-MM-DD')
              let start = moment(lastSubscription.start_date)
              let end = moment(lastSubscription.end_date)
              let daysDiff = end.diff(start, 'days') + 1
              this.subscription.end_date = moment(lastSubscription.end_date).add(daysDiff, 'days').format('YYYY-MM-DD')
            }

            this.subscriptionDialog = true
          })
      },
      removeSubscription () {

      },
      saveSubscription () {
        this.loading = true
        if (this.subscription.id) {
          this.error = null
          this.fieldsErrors = []
          subscriptionsApi
            .updateSubscription(this.subscription)
            .then(response => {
              this.subscription = response
              this.subscriptionDialog = false
              this.loading = false
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        } else {
          subscriptionsApi
            .createSubscription(this.subscription)
            .then(response => {
              this.subscription = response
              this.subscriptionDialog = false
              if (this.subscription.price > 0) {
                let client = this.subscription.client
                client.state = 'success'
                clientsApi
                  .updateClient(client)
                  .then(response => {
                    this.$refs.fullCalendar.getApi().refetchEvents()
                    this.loading = false
                  })
                  .catch(error => {
                    this.error = this.pretty(error.response.data)
                  })
              } else {
                this.$refs.fullCalendar.getApi().refetchEvents()
                this.loading = false
              }
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.$refs.fullCalendar.getApi().refetchEvents()
              this.loading = false
            })
        }
      },
      lessonsByDatesFilter (subscription) {
        if (this.invalidateCounter && this.dopInvalidateCounter && subscription.start_date && subscription.end_date && subscription.timetable_id) {
          let lessons = []
          let start = new Date(subscription.start_date)
          let end = new Date(subscription.end_date)
          let timetableId = subscription.timetable_id
          for (var i = 0; i < this.timetables.length; i++) {
            if (timetableId === this.timetables[i].id) {
              var timetable = this.timetables[i]
              break
            }
          }

          var loop = new Date(start)
          while (loop <= end) {
            if (moment(loop).isoWeekday() === 1 && timetable.pn === 1) {
              lessons.push('ПН ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_pn.slice(0, -3))
            } else if (moment(loop).isoWeekday() === 2 && timetable.vt === 1) {
              lessons.push('ВТ ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_vt.slice(0, -3))
            } else if (moment(loop).isoWeekday() === 3 && timetable.sr === 1) {
              lessons.push('СР ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_sr.slice(0, -3))
            } else if (moment(loop).isoWeekday() === 4 && timetable.ch === 1) {
              lessons.push('ЧТ ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_ch.slice(0, -3))
            } else if (moment(loop).isoWeekday() === 5 && timetable.pt === 1) {
              lessons.push('ПТ ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_pt.slice(0, -3))
            } else if (moment(loop).isoWeekday() === 6 && timetable.sb === 1) {
              lessons.push('СБ ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_sb.slice(0, -3))
            } else if (moment(loop).isoWeekday() === 7 && timetable.vs === 1) {
              lessons.push('ВС ' + moment(loop).format('YYYY-MM-DD') + ' ' + timetable.time_vs.slice(0, -3))
            }
            var newDate = loop.setDate(loop.getDate() + 1)
            loop = new Date(newDate)
          }
          return lessons
        }
        return []
      },
      onTemplateChange (event) {
        if (this.selectedTemplate && this.subscription) {
          this.subscription.price = this.selectedTemplate.price
          if (this.selectedTemplate.lessons_count) {
            this.subscription.lessons_count = this.selectedTemplate.lessons_count
          }
        }
      },
      onTimetableChange (event) {
        // костыль для исправления глюка, когда изменение дочернего свойства
        // в v-select не приводит к обновлению lessonsByDates
        this.invalidateCounter++
      },

      onDopTimetableChange (event) {
        // костыль для исправления глюка, когда изменение дочернего свойства
        // в v-select не приводит к обновлению lessonsByDates
        this.dopInvalidateCounter++
      },

      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },

      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },

      isNumber (value) {
        return ((value != null) &&
          (value !== '') &&
          !isNaN(Number(value.toString())))
      },

      minusDay (date) {
        date = moment(date).add(-1, 'days').format('YYYY-MM-DD')
        return date
      },
    },
  }

</script>

<style lang="scss">
  // you must include each plugins' css
  // paths prefixed with ~ signify node_modules
  @import '~@fullcalendar/core/main.css';
  @import '~@fullcalendar/daygrid/main.css';
  @import '~@fullcalendar/timegrid/main.css';
  @import '~@fullcalendar/timeline/main.css';
  @import '~@fullcalendar/resource-timeline/main.css';

  .v-toolbar__title {
    text-transform: capitalize;
  }

  a.fc-timeline-event {
    color: white !important;
  }

  .fc-sat, .fc-sun {
    background: rgba(255, 0, 32, 0.07);
  }

  .fc-resource-area .fc-cell-content {
    cursor: pointer;
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }

  .fc-timeline .fc-cell-text {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .fc-widget-header span.fc-cell-text {
    text-align: center !important;
    display: block !important;
  }

  .group-chip {
    height: auto !important;
    margin-bottom: 1px !important;
  }
</style>
